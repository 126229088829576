import React from 'react'
import ReactDOM from 'react-dom'
import InputTancod from './InputTancod'
import MyDateBetweenPicker from './MyDateBetweenPicker'
import MyDatePicker from './MyDatePicker'
import SelectBothPerson from './SelectBothPerson'
import SelectJyumin from './SelectJyumin'
import SelectStaffs from './SelectStaffs'
import StaffControlSplitButton from './StaffControlSplitButton'

function reactDomRender (id, component) {
    let target = document.getElementById(id)
    console.log(target)
    if ( target !== null ) {
        ReactDOM.render(component, target)
    }
}

reactDomRender('input-tancod', <InputTancod />)
reactDomRender('date-between-picker', <MyDateBetweenPicker />)
reactDomRender('date-between-picker-past', <MyDateBetweenPicker variant={"past-date-range"} />)
reactDomRender('date-between-picker-simple', <MyDateBetweenPicker simple={true} />)
reactDomRender('date-picker', <MyDatePicker />)
reactDomRender('select-both-person', <SelectBothPerson />)
reactDomRender('select-jyumin', <SelectJyumin />)
reactDomRender('select-staffs', <SelectStaffs />)
reactDomRender('staff-control-split-button', <StaffControlSplitButton />)
