import moment from 'moment'
import qs from 'qs'
import { Map } from 'immutable'

/**
 * JST基準に変換して返す
 * @param {string} dateTimeStr YYYY-MM-DDTHH:mm:00Z
 * @returns {moment.Moment}
 */
export const parseAsMoment = (dateTimeStr) => {
    return moment.utc(dateTimeStr, 'YYYY-MM-DDTHH:mm:00Z', 'ja').utcOffset(9)
}

/**
 * 日付形式に変換して返す
 * @param {moment.Moment} momentInstance
 * @returns {string}
 */
export const toUtcIso8601str = (momentInstance) => {
    return momentInstance
        .clone()
        .utc()
        .format('YYYY-MM-DDTHH:mm:00Z')
}


/**
 * クエリ文字列のパース
 * @param {*} queryStr 
 * @param {*} props 
 */
export const loadCondQuery = (search, props=undefined) => {
    let queryStr = qs.parse(location.search, { ignoreQueryPrefix: true })
    let now = moment().format("YYYY/MM/DD")
    let defaultState = { sd: now, ed: now, }

    let sd = moment(queryStr.sd, "YYYYMMDD")
    let ed = moment(queryStr.ed, "YYYYMMDD")
    let kcodes = queryStr.kcodes
    let tancods = queryStr.tancods
    let yojikbn = queryStr.yojikbn
    let sortby = queryStr.sortby
    let filterby = queryStr.filterby

    let cond =  new Map({
        //system_code: system_code,
        sd: sd.isValid() ? sd.format('YYYY/MM/DD') : defaultState.sd,
        ed: ed.isValid() ?  ed.format('YYYY/MM/DD') : defaultState.ed,
        kcodes: Array.isArray(kcodes) ? kcodes : [],
        tancods: Array.isArray(tancods) ? tancods : [],
        //office_code: "",
        yojikbn,
        sortby,
        filterby,
    })

    return cond.map( _c => _c ? _c : "" ).toJS()
}


