import React, { useState } from 'react'


const InputTancod = props => {
    const [ tmp, setTmp]  = useState("")
    const [ value, setValue ]  = useState("")

    const handleChange = e => {
        setTmp(e.target.value)
        setValue(e.target.value.padStart(5, '0'))
    }

    return (
        <React.Fragment>
            <label class="control-label" for="password">ユーザー</label>
            <input 
                type="text" 
                name="code-dummy" 
                id="code-dummy" 
                className="form-control" 
                value={tmp} 
                onChange={handleChange}
                />
            <input 
                type="hidden" 
                name="code" 
                id="code" 
                className="form-control" 
                value={value} 
                />
        </React.Fragment>
   )
}

export default InputTancod