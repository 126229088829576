import React, { useReducer } from 'react'
import moment from 'moment'

import { makeStyles } from "@material-ui/core/styles"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import NextIcon from '@material-ui/icons/ArrowForwardIos'
import BeforeIcon from '@material-ui/icons/ArrowBackIos'
import { withStyles } from '@material-ui/core/styles'

import { createBrowserHistory } from 'history'

import { loadCondQuery } from './common'


import DatePicker from 'react-ja-date-picker'

const useStyles = makeStyles( theme => ({
    inputContainer: {
        position: "relative",
        //display: "table",
        borderCollapse: "separate",
    },
    inputBaseInput: {
        padding: "16px 12px 8px 12px",
        width: "11rem",
    },
    inputBaseRoot: {
        fontSize: "1.5rem",
    },
    inputLabelBaseRoot: {
        fontSize: "1.5rem",
    },
    inputSeparator: {
        display: "table-cell", 
        verticalAlign: "middle",
        padding: theme.spacing(1),
    },
    setButtonRoot: {
        display: "flex", 
        justifyContent: "start", 
        marginTop: 8, 
    },
    setButtonGroup: {
        marginRight: 16,
        minWidth: 145,
    },
    setFewButtonGroup: {
        marginRight: 16,
        minWidth: 145,
        display: "flex",
        justifyContent: "space-around",
    },
    setButton: {
        minWidth: "auto",
        padding: "5px 13.4px",
    },
}))

moment.locale("ja");

const history = createBrowserHistory()
//console.log(history.location.search)
const queryStrObj = loadCondQuery(history.location.search)

const nowDate = new Date()
const initialState = Object.assign({}, { sd: nowDate, ed: nowDate, whichSet: '' }, queryStrObj)
console.log(initialState)

const reducer = (state, action) => {
    //console.log(state, action)
    switch (action.type) {
        case 'openSd':
            return { ...state, whichSet: 'sd' }
        case 'openEd':
            return { ...state, whichSet: 'ed' }
        case 'closeDlg':
            return { ...state, whichSet: '' }
        case 'setDt':
            return { ...state, [action.setto || state.whichSet]: action.dt, whichSet: '' }
        case 'setBothDt':
            return { ...state, sd: action.dt, ed: action.dt, whichSet: '' }
        default:
            throw new Error();
    }
}

const MyDateBetweenPicker = ({
    simple,
    variant,
}) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initialState);

    const handleClickDay = (y, m, d) => {
        let dt = moment(`${y}-${m}-${d}`, 'YYYY-M-D').toDate()
        dispatch({ type: 'setDt', dt })
    }

    const handleBothDay = order => () => {
        let today = nowDate
        let sd = moment(new Date(state.sd))
        let ed = moment(new Date(state.ed))
        if ( order === "today" )      return dispatch({ type: 'setBothDt', dt: today })
        if ( order === "yesterday" )  return dispatch({ type: 'setBothDt', dt: sd.add(-1, 'd').toDate() })
        if ( order === "tomorrow" )   return dispatch({ type: 'setBothDt', dt: sd.add( 1, 'd').toDate() })
        if ( order === "past_week" )  return dispatch({ type: 'setDt', dt: ed.add(-1, 'w').toDate(), setto: 'sd' })
        if ( order === "past_month" ) return dispatch({ type: 'setDt', dt: ed.add(-1, 'M').toDate(), setto: 'sd' })
        // 後ろの日付のみ変更
        if ( order === "period_yesterday" )  return dispatch({ type: 'setDt', dt: ed.add(-1, 'd').toDate(), setto: 'ed' })
        if ( order === "period_tomorrow" )   return dispatch({ type: 'setDt', dt: ed.add( 1, 'd').toDate(), setto: 'ed' })
    }

    const handleOpen = type => () => {
        dispatch({type})
    }

    const formatDate = (mdt, format) => moment(mdt).format(format)

    return(
        <React.Fragment >
            <Dialog open={state.whichSet !== initialState.whichSet}>
                <DatePicker
                    date={formatDate(state[state.whichSet], 'YYYY-MM-DD')}
                    type={'button'}
                    onSelect={handleClickDay}
                />
                <DialogActions>
                    <Button variant="contained" size="small" 
                        onClick={(e) => dispatch({type: 'closeDlg'})}
                    >閉じる</Button>

                </DialogActions>
            </Dialog>
            <div className={classes.inputContainer} >
                <div style={{ display: "table-row", lineHeight: 2, }} >
                <TextField
                    label="開始日"
                    variant="outlined"
                    className="form-control"
                    InputProps={{ className: classes.inputBaseRoot, classes: { input: classes.inputBaseInput } }}
                    InputLabelProps={{ className: classes.inputLabelBaseRoot }}
                    value={formatDate(state.sd, 'YYYY/MM/DD (dd)')}
                    onClick={handleOpen('openSd')}
                    //className={classes.text}
                />
                <span className={classes.inputSeparator}>～</span>
                <TextField
                    label="終了日"
                    variant="outlined"
                    className="form-control"
                    InputProps={{ className: classes.inputBaseRoot, classes: { input: classes.inputBaseInput } }}
                    InputLabelProps={{ className: classes.inputLabelBaseRoot }}
                    value={formatDate(state.ed, 'YYYY/MM/DD (dd)')}
                    onClick={handleOpen('openEd')}
                    //className={classes.text}
                />
                </div>

                { simple ? null : (
                    <div className={classes.setButtonRoot} >
                        <div className={classes.setButtonGroup}>
                            <Button 
                                variant="outlined" 
                                className={classes.setButton}
                                onClick={handleBothDay('yesterday')}
                                ><BeforeIcon /></Button>
                            <Button 
                                variant="outlined" 
                                className={classes.setButton}
                                onClick={handleBothDay('today')}
                                >今日</Button>
                            <Button 
                                variant="outlined" 
                                className={classes.setButton}
                                onClick={handleBothDay('tomorrow')}
                                ><NextIcon /></Button>
                        </div>
                        { variant === "past-date-range" ? (
                            <div className={classes.setFewButtonGroup}>
                                <Button
                                    variant="outlined"
                                    className={classes.setButton}
                                    onClick={handleBothDay('past_week')}
                                    >～１週間</Button>
                                <Button
                                    variant="outlined"
                                    className={classes.setButton}
                                    onClick={handleBothDay('past_month')}
                                    >～１ヶ月</Button>

                            </div>

                        ) : (
                            <div className={classes.setFewButtonGroup}>
                                <Button 
                                    variant="outlined" 
                                    className={classes.setButton}
                                    onClick={handleBothDay('period_yesterday')}
                                    ><BeforeIcon /></Button>
                                <Button 
                                    variant="outlined" 
                                    className={classes.setButton}
                                    onClick={handleBothDay('period_tomorrow')}
                                    ><NextIcon /></Button>

                            </div>

                        ) }
                    </div>
                    ) }
            </div>
            <input type="hidden" name="sd" value={formatDate(state.sd, 'YYYYMMDD')} />
            <input type="hidden" name="ed" value={formatDate(state.ed, 'YYYYMMDD')} />
        </React.Fragment>
    )
}

export default MyDateBetweenPicker
