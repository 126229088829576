import React, { useState, useEffect, useReducer } from 'react'
import moment from 'moment'

import { makeStyles } from "@material-ui/core/styles"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import BeforeIcon from '@material-ui/icons/ArrowBackIos'
import NextIcon from '@material-ui/icons/ArrowForwardIos'
import { withStyles } from '@material-ui/core/styles'

import { createBrowserHistory } from 'history'

import { loadCondQuery } from './common'


import DatePicker from 'react-ja-date-picker'

const useStyles = makeStyles( theme => ({
    inputBaseInput: {
        padding: "16px 12px 8px 12px",
        width: "11rem",
    },
    inputBaseRoot: {
        fontSize: "1.5rem",
    },
    inputLabelBaseRoot: {
        fontSize: "1.5rem",
    },
    setButtonRoot: {
        display: "flex", 
        justifyContent: "start", 
        marginTop: 8, 
    },
    setButtonGroup: {
        marginRight: 16,
        minWidth: 145,
    },
    setButton: {
        minWidth: "auto",
        padding: "5px 13.4px",
    },
}))

moment.locale("ja");

const history = createBrowserHistory()
//console.log(history.location.search)
const queryStrObj = loadCondQuery(history.location.search)

const nowDate = new Date()
const initialState = Object.assign({}, { dt: nowDate, whichSet: '' }, queryStrObj)
console.log(initialState)

const reducer = (state, action) => {
    //console.log(state, action)
    switch (action.type) {
        case 'openDt':
            return { ...state, whichSet: 'dt' }
        case 'closeDlg':
            return { ...state, whichSet: '' }
        case 'setDt':
            return { ...state, [action.setto || state.whichSet]: action.dt, whichSet: '' }
        default:
            throw new Error();
    }
}

const MyDatePicker = () => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [label, setLabel] = useState("日付");

    const parentRef = React.createRef()

    useEffect(() => {
        setLabel(parentRef.current.parentNode.getAttribute('label'))
    }, [])

    const handleClickDay = (y, m, d) => {
        let dt = moment(`${y}-${m}-${d}`, 'YYYY-M-D').toDate()
        dispatch({ type: 'setDt', dt })
    }

    const handleSetButton = order => () => {
        let today = nowDate
        let dt = moment(new Date(state.dt))
        if ( order === "today" )      return dispatch({ type: 'setDt', dt: today, setto: 'dt' })
        if ( order === "yesterday" )  return dispatch({ type: 'setDt', dt: dt.add(-1, 'd').toDate(), setto: 'dt' })
        if ( order === "tomorrow" )   return dispatch({ type: 'setDt', dt: dt.add( 1, 'd').toDate(), setto: 'dt' })
        //if ( order === "past_week" )  dispatch({ type: 'setDt', dt: ed.add(-1, 'w').toDate(), setto: 'sd' })
        //if ( order === "past_month" ) dispatch({ type: 'setDt', dt: ed.add(-1, 'M').toDate(), setto: 'sd' })
    }

    const handleOpen = type => () => {
        dispatch({type})
    }

    const formatDate = (mdt, format) => moment(mdt).format(format)

    return(
        <div ref={parentRef} >
            <Dialog open={state.whichSet !== initialState.whichSet}>
                <DatePicker
                    date={formatDate(state[state.whichSet], 'YYYY-MM-DD')}
                    type={'button'}
                    onSelect={handleClickDay}
                />
                <DialogActions>
                    <Button variant="contained" size="small" 
                        onClick={(e) => dispatch({type: 'closeDlg'})}
                    >閉じる</Button>

                </DialogActions>
            </Dialog>

            <div style={{ display: "table-row", lineHeight: 3, }} >
                <TextField
                    label={label}
                    variant="outlined"
                    className="form-control"
                    InputProps={{ className: classes.inputBaseRoot, classes: { input: classes.inputBaseInput } }}
                    InputLabelProps={{ className: classes.inputLabelBaseRoot }}
                    value={formatDate(state.dt, 'YYYY/MM/DD (dd)')}
                    onClick={handleOpen('openDt')}
                    //className={classes.text}
                />
                </div>

            <div className={classes.setButtonRoot} >
                <div className={classes.setButtonGroup}>
                    <Button 
                        variant="outlined" 
                        className={classes.setButton}
                        onClick={handleSetButton('yesterday')}
                        ><BeforeIcon /></Button>
                    <Button 
                        variant="outlined" 
                        className={classes.setButton}
                        onClick={handleSetButton('today')}
                        >今日</Button>
                    <Button 
                        variant="outlined" 
                        className={classes.setButton}
                        onClick={handleSetButton('tomorrow')}
                        ><NextIcon /></Button>
                </div>
            </div>
            <input type="hidden" name="dt" value={formatDate(state.dt, 'YYYYMMDD')} />
        </div>
    )
}

export default MyDatePicker
