import React from 'react'

import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}))

export default function InputPassword(props) {
    const classes = useStyles();
    return (
        <InputBase
            className={classes.input}
            placeholder="パスワード入力"
            inputProps={{ 'aria-label': 'search google maps', 'type': 'password', 'name': props.name }}
        />
    )
}