import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
//import IconButton from '@material-ui/core/IconButton';
//import MenuIcon from '@material-ui/icons/Menu';
//import SearchIcon from '@material-ui/icons/Search';
//import DirectionsIcon from '@material-ui/icons/Directions';

import InputPassword from './InputPassword'

const options = [
    { label: 'チェック対象をログイン許可', name: 'ENABLE_LOGIN', },
    { label: 'チェック対象を許可取り消し', name: 'DISABLE_LOGIN', },
    //{ label: 'チェック対象をパスワード設定', name: 'SET_PASSWORD', },
]


const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  button: {
    fontSize: "1.5rem"
  },
}));

export default function SplitButton() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex].label}`)
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
    return
    }

    setOpen(false)
  }

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>

      {/* <Paper className={classes.root}> */}

          {/* <InputPassword name='password' /> */}

          {/* <Divider className={classes.divider} orientation="vertical" /> */}

          <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
          <Button 
            type="submit" 
            //size="large"
            form="staff-control" // レイアウト変更を考慮して念の為
            name={options[selectedIndex].name} 
            onClick={handleClick}
            className={classes.button}
            >{options[selectedIndex].label}</Button>
          <Button
            color="primary"
            //size="large"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
          </ButtonGroup>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
            >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu">
                {options.map((option, index) => (
                <MenuItem
                  key={option.name}
                  disabled={index === 2}
                  selected={index === selectedIndex}
                  onClick={event => handleMenuItemClick(event, index)}
                >
                  {option.label}
                </MenuItem>
                ))}
              </MenuList>
              </ClickAwayListener>
            </Paper>
            </Grow>
          )}
          </Popper>
        {/* </Paper> */}
      </Grid>
    </Grid>
  )
}