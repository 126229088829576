import React, { useReducer } from 'react'

import ReactSelect from 'react-select'
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles( theme => ({
    input: {
        display: 'flex',
        //padding: 0,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
    },
}))

const reducer = (state, action) => {
    //console.log(state, action)
    switch (action.type) {
        case 'change-value':
            return { ...state, [action.paramName]: action.value }
        default:
            throw new Error()
    }
}

const SelectPerson = props => {
    const [state, dispatch] = useReducer(reducer, props.initialState)
    const classes = useStyles()

    const handleChangeList = type => value => {
        dispatch({ 
            type, 
            value: value ? value.map( item => item.value ) : [],
            paramName: props.paramName,
         })
    }
     
    let tmpText = state[props.paramName].map( _c => {
        let tmpObj = state.list.find( _item => _item.code === _c )
        if ( !tmpObj ) return { value: _c, label: _c }
        const { code: value, concatname } = tmpObj
        return { value, label: `${String(Number(value))}: ${concatname}` }
        //let tmpObj = state.list.find( _item => _item.code === _c )
        //return tmpObj ? { value: tmpObj.code, label: tmpObj.concatname } : { value: _c, label: _c }
     })


    return(
        <React.Fragment >
            <ReactSelect
                classes={classes}
                // 規模が大きいと全表示時に重すぎるため、take() で制限していた
                //options={state.list.map(_s => ({ value: _s.code, label: _s.concatname })).take(500).toJS()}
                options={state.list.map(_s => ({ value: _s.code, label: _s.concatname })).toJS()}
                value={tmpText}
                onChange={handleChangeList('change-value')}
                placeholder={props.placeholder}
                isMulti
            />
            { state[props.paramName].map( item => (
                <input key={`select-${item}`} type="hidden" name={`${props.paramName}[]`} value={item}  />
            )) }
        </React.Fragment>
    )
}

export default SelectPerson
