import React from 'react'

import moment from 'moment'
import { List } from 'immutable'
import { createBrowserHistory } from 'history'
import Paper from "@material-ui/core/Paper"
//import Grid from "@material-ui/core/Grid"
import { makeStyles } from '@material-ui/core/styles'

import SelectPerson from './SelectPerson'
import { loadCondQuery } from './common'

moment.locale("ja")

const useStyles = makeStyles( theme => ({
    paper: {
        //padding: theme.spacing.unit,
        padding: 8,
        textAlign: "center",
        minHeight: "80%",
        display: "flex",
        alignItems: "center",
        //height: "90%",
    },
}))


const history = createBrowserHistory()
//console.log(history.location.search)
const queryStrObj = loadCondQuery(history.location.search)

const staffInitialState = Object.assign({}, { 
    list: new List(window.STAFF_LIST), 
    tancods: [], 
}, queryStrObj)

const jyuminInitialState = Object.assign({}, { 
    list: new List(window.JYUMIN_LIST), 
    kcodes: [], 
}, queryStrObj)

const SelectBothPerson = () => {
    const classes = useStyles()

    return(
        <div style={{ width: "100%" }}>
            <SelectPerson 
                paramName="kcodes"
                placeholder="利用者氏名／コード"
                initialState={jyuminInitialState}
                />
            <SelectPerson 
                paramName="tancods"
                placeholder="担当者氏名／コード"
                initialState={staffInitialState}
                />
        </div>
    )
}

export default SelectBothPerson
